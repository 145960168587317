<template>
  <div class="admin-masterclass">
    <div class="admin-masterclass-header">
      <div class="admin-masterclass-header-title">
        <h2 class="color-primary">Masterclasses</h2>
        <h4>List of Masterclasses</h4>
        <p>You can write and publish masterclasses for the YSA Program.</p>
      </div>
      <a class="btn btn-create-list mx-2 h-25" href="/admin/ysa/masterclass/form">
        <span class="icon"><img src="/images/icon/plus-circle.svg" alt="download-icon" /></span>
        <span class="admin-button-text">Add Masterclass</span>
      </a>
    </div>
    <div class="admin-masterclass-content">
      <TableData :headers="dynamicHeaders" :items="masterclasses" :is-action="true" :isEditAction="true"
        :isDeleteAction="true" :loading="isLoading" :total-pages="totalPages" :current-page="currentPage"
        :use-api-sort="true" @action="handleAction" />
    </div>

    <!-- Confirmation Modal -->
    <ConfirmationModal v-model="showDeleteModal" title="Delete Masterclass" :message="`Are you sure you want to delete '${selectedMasterclass?.title || ''
      }'?`" confirm-text="Delete" cancel-text="Cancel" confirm-color="error" :loading="isDeleting"
      @confirm="confirmDelete" @cancel="cancelDelete" />
  </div>
</template>

<script setup>
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import TableData from "@/components/common/TableData.vue";
import ysaAdminService from "@/services/ysaAdminService";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const masterclasses = ref([]);
const router = useRouter();
const isLoading = ref(false);
const showDeleteModal = ref(false);
const selectedMasterclass = ref(null);
const isDeleting = ref(false);

const totalPages = ref(0);
const currentPage = ref(1);

const filters = ref({
  page: 1,
  limit: 10,
  sort_by: "created_at",
  sort_order: "desc",
});

const dynamicHeaders = ref([
  {
    text: "Status",
    value: "status",
    sortable: true,
  },
  {
    text: "Title",
    value: "title",
    sortable: true,
  },
  {
    text: "Category",
    value: "category",
  },
]);

const handleAction = ({ type, item }) => {
  if (type === "edit") {
    router.push({
      name: "ysaMasterclassEdit",
      params: { id: item.id },
    });
  }
  if (type === "delete") {
    selectedMasterclass.value = item;
    showDeleteModal.value = true;
  }
};

const confirmDelete = async () => {
  if (!selectedMasterclass.value) return;

  isDeleting.value = true;
  try {
    await ysaAdminService.deleteMasterclass(selectedMasterclass.value.id);
    // Remove the deleted masterclass from the list
    masterclasses.value = masterclasses.value.filter(
      (item) => item.id !== selectedMasterclass.value.id
    );
    showDeleteModal.value = false;
  } catch (error) {
    console.error("Failed to delete masterclass:", error);
  } finally {
    isDeleting.value = false;
  }
};

const cancelDelete = () => {
  // selectedMasterclass.value = null;
  // showDeleteModal.value = false;
};

const fetchMasterclasses = async () => {
  isLoading.value = true;
  try {
    const response = await ysaAdminService.getMasterclasses(filters.value);
    const data = response.data?.data;
    const masterclassesData = data?.rows;
    totalPages.value = data?.total_page;
    currentPage.value = data?.page;

    if (masterclassesData) {
      masterclasses.value = masterclassesData.map((item) => {
        const category = [];
        if (item.is_changemaker) {
          category.push("Sustainability Youth Changemaker");
        }
        if (item.is_innovator) {
          category.push("Sustainability Youth Innovator");
        }

        return {
          id: item.id,
          status: item.status.charAt(0).toUpperCase() + item.status.slice(1),
          title: item.title,
          category: category.join(", ") || "-",
          disableAction: {
            edit: item.status === "published",
          },
        };
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchMasterclasses();
});
</script>

<style scoped>
.admin-masterclass {
  padding: 40px;
}

.admin-masterclass-header-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-masterclass-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 20px;
  border-bottom: 2px solid #f2f3fb;
  padding-bottom: 20px;
}
</style>
